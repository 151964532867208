import React, { useEffect, useState, useMemo } from 'react';
import { Box, Popover, TextField, Tabs, Tab, ArrowDropDownIcon } from '@esgian/esgianui';
import { DATE_FORMAT, TIME_PERIOD_TABS } from '@constants';
import { useSelector } from 'react-redux';
import { fetchRigWells } from '@api/DdrReport';
import moment from 'moment';
import TimePeriod from './TimePeriod';
import SavedTimePeriod from './SavedTimePeriod';
import PropTypes from 'prop-types';
import WellSelect from './WellSelect';
import { TextWithTooltipIcon } from '@components/Display';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';

const DateRangeDropdown = ({
  startDate,
  endDate,
  selectedDateName,
  onSave,
  inputSize,
  inputVariant,
  error,
  helpertext,
  includeWellDetails,
  well,
  fullWidth,
  allowTimeZoneChange,
  currentRig,
  preferredValue,
  maxWidth,
  selectedRig,
  selectedDateType
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(TIME_PERIOD_TABS.DATA_PICKER);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
  const [selectedWell, setSelectedWell] = useState(null);
  const [wellsData, setWellsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const open = Boolean(anchorEl);

  const subText = useMemo(() => {
    if (includeWellDetails && well) {
      return `Well: ${well.wellname}`;
    }
    if (error && helpertext) {
      return helpertext;
    }
    return '';
  }, [includeWellDetails, helpertext, well, error]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTimePeriodSelection = (item) => {
    onSave(item);
    setSelectedTimePeriod(null);
    setSelectedWell(null);
    setTabValue(TIME_PERIOD_TABS.DATA_PICKER);
  };

  const handleSaveTimePeriodSelection = (item) => {
    onSave(item);
    setSelectedWell(null);
    setTabValue(TIME_PERIOD_TABS.DATA_PICKER);
  };

  const handleWellSelectSelection = (item) => {
    onSave(item);
    setSelectedTimePeriod(null);
  };

  useEffect(() => {
    if (selectedDateType !== 'well') {
      setSelectedWell(null);
      setTabValue(TIME_PERIOD_TABS.DATA_PICKER);
    } else {
      setTabValue(TIME_PERIOD_TABS.WELL);
    }
  }, [open]);

  useEffect(() => {
    let rig = currentRig;
    if (!currentRig) {
      rig = selectedRig;
    }
    if (rig) {
      setIsLoading(true);
      const endDate = moment().format(DATE_FORMAT);
      fetchRigWells(null, { selectedRig: rig, endDate })
        .then((result) => {
          const filteredWells = result
            .filter(
              ({ starttime, endtime }) =>
                moment(endtime).diff(moment(starttime), 'days') >= 3 &&
                moment(starttime).isSameOrAfter(moment(rig.lowerLimit)) &&
                moment(endtime).isSameOrBefore(moment())
            )
            .sort(
              (a, b) =>
                moment.parseZone(b.starttime).valueOf() - moment.parseZone(a.starttime).valueOf()
            );

          // Filter by unique wellname
          const uniqueWells = [];
          const wellNames = new Set();

          filteredWells.forEach((well) => {
            if (!wellNames.has(well.wellname)) {
              wellNames.add(well.wellname);
              uniqueWells.push(well);
            }
          });

          setWellsData(uniqueWells);
        })
        .catch(() => setWellsData([]))
        .finally(() => setIsLoading(false));
    }
  }, [selectedRig, currentRig]);

  const value = useMemo(() => {
    if (currentRig) {
      if (preferredValue) {
        return preferredValue;
      }
    }

    if (selectedDateType) {
      return selectedDateName;
    }

    return `${startDate ? moment.parseZone(startDate).format(DATE_FORMAT) : ''} - ${
      endDate ? moment.parseZone(endDate).format(DATE_FORMAT) : ''
    }`;
  }, [currentRig, selectedDateType, selectedDateName, startDate, endDate]);

  useEffect(() => {
    if (selectedDateType === 'well') {
      const selectedWellDateName = selectedWell ? selectedWell?.wellname : null;
      const dateName = selectedDateName?.split(':')?.[0];
      if (selectedWellDateName === dateName) return;

      wellsData.forEach((well, index) => {
        if (well.wellname === dateName) {
          setSelectedWell({ ...well, index });
          return;
        }
      });
    }
  }, [selectedDateName]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {console.log('>>> ')}
      <TextField
        disabled={!selectedRig && !currentRig}
        onClick={handleOpen}
        variant={inputVariant === 'noBorder' ? 'standard' : inputVariant}
        error={error}
        inputProps={{
          sx: {
            textAlign: 'start',
            ...(inputVariant === 'noBorder' && {
              padding: 0,
              width: `${Math.max(value.length * 7, 1)}px`,
              fontSize: 14,
              cursor: 'pointer'
            })
          }
        }}
        label={inputVariant === 'noBorder' ? undefined : 'Period'}
        autoComplete="off"
        helperText={subText}
        InputProps={{
          sx: {
            textAlign: 'start',
            height: '47px',
            padding: '0 !important',
            lineHeight: '47px',
            ...(inputVariant === 'noBorder' && {
              '&:after': {
                display: 'none !important'
              },
              '&:before': {
                display: 'none !important'
              },
              '& .MuiInputBase-input': {
                padding: '0 8px 0 0 '
              }
            }),
            '&:hover': {
              svg: {
                color: ({ palette }) => palette.primary.main
              }
            }
          },
          readOnly: true,
          value,
          ...(inputVariant === 'noBorder' && {
            endAdornment: <ArrowDropDownIcon />
          })
        }}
        sx={{
          width: fullWidth ? '100%' : 'auto',
          maxWidth,
          ...(inputVariant === 'noBorder' && { height: 20 })
        }}
        id={'date-range-dropdown'}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Box
          sx={{
            width: '586px',
            // height: '584px',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="date picker tabs"
            variant="fullWidth"
            sx={{
              mt: 3,
              minHeight: '40px',
              height: '40px',
              '& .MuiTab-root': {
                minHeight: 'auto',
                height: 'auto',
                textTransform: 'none',
                py: '8px'
              }
            }}>
            <Tab label="Time period" />
            <Tab label="Saved time period" />
            <Tab
              label={
                <TextWithTooltipIcon
                  iconSize={'16px'}
                  label="Well"
                  tooltipText="Please note that well selection will automatically change timezone to rig timezone for the time period of the well that is selected."
                />
              }
            />
          </Tabs>
          <Box sx={{ flexGrow: 1, color: 'white', padding: 5 }}>
            <TimePeriod
              display={tabValue === TIME_PERIOD_TABS.DATA_PICKER}
              setTabValue={setTabValue}
              handleClose={handleClose}
              onSave={handleTimePeriodSelection}
              startDate={startDate}
              endDate={endDate}
              setSelectedTimePeriod={setSelectedTimePeriod}
            />
            {tabValue === TIME_PERIOD_TABS.SAVE_TIME && (
              <SavedTimePeriod
                setTabValue={setTabValue}
                setSelectedTimePeriod={setSelectedTimePeriod}
                selectedTimePeriod={selectedTimePeriod}
                handleClose={handleClose}
                startDate={startDate}
                endDate={endDate}
                onSave={handleSaveTimePeriodSelection}
              />
            )}
            {tabValue === TIME_PERIOD_TABS.WELL && (
              <WellSelect
                onSave={handleWellSelectSelection}
                handleClose={handleClose}
                selectedWell={selectedWell}
                setSelectedWell={setSelectedWell}
                wellsData={wellsData}
                isLoading={isLoading}
                allowTimeZoneChange={allowTimeZoneChange}
              />
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

DateRangeDropdown.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedDateName: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  inputSize: PropTypes.oneOf(['small', 'medium']),
  inputVariant: PropTypes.oneOf(['standard', 'outlined', 'filled', 'noBorder']),
  error: PropTypes.bool,
  well: PropTypes.object,
  includeWellDetails: PropTypes.bool,
  helpertext: PropTypes.string,
  fullWidth: PropTypes.bool,
  allowTimeZoneChange: PropTypes.bool,
  currentRig: PropTypes.object,
  preferredValue: PropTypes.string,
  maxWidth: PropTypes.number,
  selectedRig: PropTypes.object,
  selectedDateType: PropTypes.string
};

DateRangeDropdown.defaultProps = {
  startDate: moment().subtract(2, 'weeks').format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  inputSize: 'medium',
  inputVariant: 'outlined',
  error: false,
  helpertext: null,
  fullWidth: false,
  allowTimeZoneChange: true,
  selectedRig: null,
  selectedDateType: null
};

export default DateRangeDropdown;
