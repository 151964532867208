import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Paper, Typography, Stack } from '@esgian/esgianui';
import CanvasTimeSeriesChart from '../CanvasTimeSeriesChart';
import { getDisplayUnit } from '../../../store/features';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { getMainPageSlice } from '../../../store/features/filters/MainPageSlice/MainPageSlice';
import { getUnixTimeStamp } from '../../../helpers';
import LogDrawer from '../../Drawers/LogDrawer/LogDrawer';
import { DATE_TIME_FORMAT, TIMESTAMP } from '../../../constants';

interface AnalyticsDataItem {
  startDate: string;
  endDate: string;
  fuelSaving: number;
  type: keyof typeof mapping;
}

const legendsColorMapping: Record<string, string> = {
  'Mud pumps': '#B965CE',
  HPU: '#1480FF',
  Engines: '#53C6CD',
  Drawworks: '#EF9FB2'
};

const mapping: Record<string, string> = {
  MP_AUX_Idle: 'Mud pumps',
  TooManyHPU: 'HPU',
  TooManyEng: 'Engines',
  DW_AUX_Idle: 'Drawworks'
};

const getSeriesAndUnit = (
  data: AnalyticsDataItem[],
  startDate: string,
  endDate: string
): ChartSeries[] => {
  if (!data.length) return [];

  const result: Record<string, { date: string; value: number; type: string }[]> = {};

  data.forEach((item) => {
    const itemStartDate = moment.parseZone(item.startDate);
    const itemEndDate = moment.parseZone(item.endDate);
    const totalMinutes = itemEndDate.diff(itemStartDate, 'minutes');
    if (totalMinutes <= 0) return;

    const perMinuteValue = item.fuelSaving / totalMinutes;

    const currentDay = itemStartDate.clone().startOf('day');

    while (currentDay.isSameOrBefore(itemEndDate, 'day')) {
      const dayStart = currentDay.clone().startOf('day');
      const dayEnd = currentDay.clone().endOf('day');

      // Calculate overlap of item range with the current day
      const start = moment.max(itemStartDate, dayStart);
      const end = moment.min(itemEndDate, dayEnd);
      const minutesInDay = end.diff(start, 'minutes');

      if (minutesInDay > 0) {
        const dayValue = perMinuteValue * minutesInDay;

        if (!result[item.type]) {
          result[item.type] = [];
        }

        const existingRecord = result[item.type].find(
          (record) => record.date === currentDay.format('YYYY-MM-DD')
        );

        if (existingRecord) {
          existingRecord.value += dayValue;
        } else {
          result[item.type].push({
            date: currentDay.format('YYYY-MM-DD'),
            value: dayValue,
            type: item.type
          });
        }
      }

      currentDay.add(1, 'day');
    }
  });

  // Ensure all dates in the range are covered
  const allDates: string[] = [];
  for (
    let date = moment(startDate).clone();
    date.isSameOrBefore(moment(endDate));
    date.add(1, 'day')
  ) {
    allDates.push(date.startOf('day').format('YYYY-MM-DD'));
  }

  Object.keys(result).forEach((type) => {
    allDates.forEach((date) => {
      const existingRecord = result[type].find((record) => record.date === date);
      if (!existingRecord) {
        result[type].push({
          date,
          value: 0,
          type
        });
      }
    });

    result[type].sort((a, b) => (a.date > b.date ? 1 : -1));
  });

  return Object.keys(result).map((key) => ({
    type: 'bar',
    backgroundColor: legendsColorMapping[mapping[key]],
    stack: 1,
    borderSkipped: true,
    label: mapping[key],
    data: result[key].map((record) => ({
      x: getUnixTimeStamp(record.date),
      y: record.value
    }))
  }));
};

interface FocusAreaOverviewChartProps {
  analyticsData: AnalyticsDataItem[];
  loading: boolean;
}

export const FocusAreaOverviewChart: React.FC<FocusAreaOverviewChartProps> = ({
  analyticsData,
  loading
}) => {
  const [logInfo, setLogInfo] = useState<{ startDate: moment.Moment; unit: string } | null>(null);
  const displayUnit = useSelector(getDisplayUnit);
  const { startDate, endDate } = useSelector(getMainPageSlice);
  const ref = useRef();

  const { series = [], unit = '' } = useMemo(() => {
    if (!analyticsData.length) return {};
    const series = getSeriesAndUnit(analyticsData, startDate, endDate);

    return { series, unit: 'mt' };
  }, [analyticsData, displayUnit, startDate, endDate]);

  const handleLogClick = useCallback((date: string) => {
    setLogInfo({ startDate: moment.utc(parseInt(date)), unit: 'day' });
  }, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Box>
          <Typography variant={'h6'}>Focus area overview</Typography>
          <Typography variant="body2">Daily focus area fuel savings potential</Typography>
        </Box>
        <CanvasTimeSeriesChart
          chartRef={ref}
          enableBarHover
          disableHoverLinePlugin
          height={'280px'}
          hideEmptyTooltipValues
          unit={unit}
          loading={loading}
          series={series}
          stackedBar
          includeTooltipSum
          handleLogClick={handleLogClick}
          fixTooltipPosition
          id="daily-focus-area-overview-chart"
        />
      </Stack>
      <LogDrawer handleClose={() => setLogInfo(null)} open={!!logInfo} logInfo={logInfo} />
    </Paper>
  );
};
