import KPIChangeDisplay from '@components/Display/KPIChangeDisplay/KPIChangeDisplay';
import { WARNINGS, WARNINGS_TYPE } from '@constants';
import { Divider, Stack } from '@esgian/esgianui';
import { getPercentDiff, getValue } from '@helpers';
import { useDisplayUnit } from '@hooks';
import { getDisplayUnit } from '@store/features';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

function AnalyticsComparisonKpis({ mainData, baselineData, loading }) {
  const displayUnit = useSelector(getDisplayUnit);
  const { valueWithUnit, getKpiDisplayText } = useDisplayUnit();

  const getSumData = (main, baseline, type) => {
    let valueKey = `${displayUnit.toLowerCase()}Saving`;
    let mainData = main.filter((item) => item.type === type) || [];
    let baseData = baseline.filter((item) => item.type === type) || [];

    let mainTot = 0;
    let baseTot = 0;
    mainData.forEach((value) => {
      mainTot += getValue(value[valueKey]);
    });
    baseData.forEach((value) => {
      baseTot += getValue(value[valueKey]);
    });

    return {
      ...valueWithUnit(mainTot - baseTot, displayUnit, true),
      percent: getPercentDiff(mainTot, baseTot)
    };
  };

  const kpis = useMemo(() => {
    if (!mainData || !baselineData) return {};
    let mainSum = 0;
    let baseSum = 0;
    let valueKey = `${displayUnit.toLowerCase()}Saving`;
    mainData.forEach((item) => {
      mainSum += getValue(item[valueKey]);
    });
    baselineData.forEach((item) => {
      baseSum += getValue(item[valueKey]);
    });
    let tooManyEng = getSumData(mainData, baselineData, WARNINGS_TYPE.TooManyEng);
    let mpIdle = getSumData(mainData, baselineData, WARNINGS_TYPE.MP_AUX_Idle);
    let hpu = getSumData(mainData, baselineData, WARNINGS_TYPE.TooManyHPU);
    let dw = getSumData(mainData, baselineData, WARNINGS_TYPE.DW_AUX_Idle);
    return {
      total: {
        ...valueWithUnit(mainSum - baseSum, displayUnit, true),
        percent: getPercentDiff(mainSum, baseSum)
      },
      dw: {
        ...dw
      },
      hpu: {
        ...hpu
      },
      mpIdle: {
        ...mpIdle
      },
      tooManyEng: {
        ...tooManyEng
      }
    };
  }, [mainData, baselineData, displayUnit]);
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-evenly'}
      divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.total?.percent}
        value={kpis.total?.value ?? null}
        unit={kpis.total?.title}
        title={getKpiDisplayText('Total')}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.tooManyEng?.percent}
        value={kpis.tooManyEng?.value ?? null}
        unit={kpis.tooManyEng?.title}
        title={`${WARNINGS.TooManyEng}`}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.mpIdle?.percent}
        value={kpis.mpIdle?.value ?? null}
        unit={kpis.mpIdle?.title}
        title={`${WARNINGS.MP_AUX_Idle}`}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.hpu?.percent}
        value={kpis.hpu?.value ?? null}
        unit={kpis.hpu?.title}
        title={`${WARNINGS.TooManyHPU}`}
      />
      <KPIChangeDisplay
        loading={loading}
        percentChange={kpis.dw?.percent}
        value={kpis.dw?.value ?? null}
        unit={kpis.dw?.title}
        title={`${WARNINGS.DW_AUX_Idle}`}
      />
    </Stack>
  );
}

AnalyticsComparisonKpis.propTypes = {
  mainData: PropTypes.arrayOf(PropTypes.object),
  baselineData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

AnalyticsComparisonKpis.defaultProps = {
  mainData: [],
  baselineData: [],
  loading: false
};

export default AnalyticsComparisonKpis;
