import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchBaselinePdfReport } from '@api/General';
import {
  Alert,
  Button,
  CircularProgress,
  CloseIcon,
  InfoOutlineIcon,
  Modal,
  Stack,
  Typography
} from '@esgian/esgianui';
import { RigSelect } from '@components/Inputs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getMainPageSlice } from '@store/features/filters/MainPageSlice/MainPageSlice';
import { getBaselineComparisonSlice } from '@store/features/filters/BaselineComparisonSlice/BaselineComparisonSlice';
import BaselineSelect from '@components/Inputs/BaselineSelect';
import { useTimezone } from '@hooks/useTimezone';
import DateRangeDropdown from '@components/DateRangeDropdown';

const initFilters = { selectedRig: null, startDate: null, endDate: null, selectedBaseline: null, selectedDateType: null };

function ExportBaselinePdfModal({ handleClose }) {
  const [filters, setFilters] = useState(initFilters);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showDownloadError, setShowDownloadError] = useState(false);
  const { selectedRig, startDate, endDate, selectedDateName, selectedDateType } = useSelector(getMainPageSlice);
  const { selectedBaseline } = useSelector(getBaselineComparisonSlice);
  const controller = new AbortController();
  const { calculateDates } = useTimezone();
  const { signal } = controller;

  useEffect(() => {
    setFilters({
      selectedRig: selectedRig,
      startDate: startDate,
      endDate: endDate,
      selectedBaseline,
      selectedDateName,
      selectedDateType: selectedDateType
    });
  }, []);

  const handleExportClick = async () => {
    // Added utc timezone format, but we need to maybe use rig time? Check with ivan about that.
    setLoadingSubmit(true);
    const { dateStart, dateEnd } = calculateDates(filters.startDate, filters.endDate, true);
    await fetchBaselinePdfReport(signal, { ...filters, startDate: dateStart, endDate: dateEnd })
      .then(() => {
        handleClose();
        setShowDownloadError(false);
      })
      .catch(() => {
        setShowDownloadError(true);
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const disableExport = useMemo(() => {
    const { selectedRig, startDate, endDate, selectedBaseline } = filters;
    return !selectedRig || !startDate || !endDate || !selectedBaseline;
  }, [filters]);

  const handleFilterChange = useCallback(
    (val) => {
      setFilters((prevState) => ({
        ...prevState,
        ...val
      }));
    },
    [filters]
  );

  return (
    <Modal
      closeIcon={<CloseIcon fontSize={'small'} />}
      PaperProps={{
        sx: {
          background: ({ palette }) => palette.neutral.neutral01
        }
      }}
      size={'xs'}
      open
      DialogActionsProps={{ sx: { justifyContent: 'center' } }}
      handleClose={handleClose}
      actions={[
        <Button
          key={'cancel-button'}
          onClick={handleClose}
          sx={{ color: (theme) => theme.palette.secondary.contrastText }}
          color={'secondary'}
          variant={'text'}>
          Cancel
        </Button>,
        <Button
          disabled={disableExport}
          key={'save-button'}
          sx={{
            color: (theme) => theme.palette.secondary.contrastText,
            borderColor: (theme) => theme.palette.secondary.contrastText
          }}
          onClick={handleExportClick}
          variant={'text'}>
          export
        </Button>
      ]}
      title={'Export to report'}>
      <Stack spacing={6} py={3} px={2}>
        <Alert
          icon={
            <InfoOutlineIcon
              fontSize={'small'}
              sx={{ color: ({ palette }) => palette.neutral.primary }}
            />
          }
          sx={{
            color: ({ palette }) => palette.neutral.primary,
            backgroundColor: ({ palette }) => palette.neutral.neutral04
          }}>
          The visualisation in the report will differ slightly from that on the website.
        </Alert>
        {showDownloadError && (
          <Alert severity={'error'}>
            Unable to download baseline report, please try again. If the problem persists please
            contact Esgian.
          </Alert>
        )}
        {loadingSubmit ? (
          <Stack alignItems={'center'} spacing={2}>
            <CircularProgress size={50} />
            <Typography variant={'h6'}>Loading...</Typography>
          </Stack>
        ) : (
          <>
            <RigSelect
              selectedRig={filters.selectedRig}
              onChange={(rig) => handleFilterChange({ selectedRig: rig })}
            />
            <DateRangeDropdown
              onSave={(period) => handleFilterChange(period)}
              startDate={filters.startDate}
              endDate={filters.endDate}
              selectedDateName={filters.selectedDateName || ''}
              fullWidth
              allowTimeZoneChange={false}
              currentRig={filters.selectedRig}
              preferredValue={filters.name}
              selectedDateType={filters.selectedDateType}
            />
            <BaselineSelect
              onChange={(baseline) => handleFilterChange({ selectedBaseline: baseline })}
              selectedBaseline={filters.selectedBaseline}
            />
          </>
        )}
      </Stack>
    </Modal>
  );
}

ExportBaselinePdfModal.propTypes = {
  handleClose: PropTypes.func.isRequired
};

ExportBaselinePdfModal.defaultProps = {};

export default ExportBaselinePdfModal;
