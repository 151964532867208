export const EQUIPMENT_TYPES = [
  {
    color: { name: 'groupOne', index: 0 },
    order: 0,
    group: 'Drilling',
    name: 'Cementing',
    key: 'Cementing'
  },
  {
    color: { name: 'groupOne', index: 1 },
    group: 'Drilling',
    order: 1,
    name: 'Pipe handling',
    key: 'PipeHandling'
  },
  {
    color: { name: 'groupOne', index: 2 },
    order: 2,
    group: 'Drilling',
    name: 'Mudpumps',
    key: 'MP'
  },
  {
    color: { name: 'groupOne', index: 3 },
    order: 3,
    group: 'Drilling',
    name: 'Ringline HPU',
    key: 'HPU'
  },
  {
    color: { name: 'groupOne', index: 4 },
    order: 4,
    group: 'Drilling',
    name: 'TopDrive',
    key: 'TD'
  },
  {
    color: { name: 'groupOne', index: 5 },
    order: 5,
    group: 'Drilling',
    name: 'Drawworks',
    key: 'DW'
  },
  {
    color: { name: 'groupOne', index: 6 },
    order: 6,
    group: 'Drilling',
    name: 'Mud treatment',
    key: 'MudTreatment'
  },
  {
    color: { name: 'groupTwo', index: 0 },
    group: 'Hotel and utilities',
    order: 7,
    name: 'Water treatment',
    key: 'WaterTreatment'
  },
  {
    color: { name: 'groupTwo', index: 1 },
    group: 'Hotel and utilities',
    name: 'Lighting',
    order: 8,
    key: 'Lighting'
  },
  {
    color: { name: 'groupTwo', index: 2 },
    order: 9,
    group: 'Hotel and utilities',
    name: 'Air',
    key: 'Air'
  },
  {
    color: { name: 'groupTwo', index: 3 },
    group: 'Hotel and utilities',
    order: 10,
    name: 'HVAC',
    key: 'HVAC'
  },
  {
    color: { name: 'groupThree', index: 7 },
    group: 'Hotel and utilities',
    order: 11,
    name: 'Water cooling',
    key: 'WaterCooling'
  },
  {
    color: { name: 'groupThree', index: 9 },
    group: 'Hotel and utilities',
    order: 12,
    name: 'Galley',
    key: 'Gallery'
  }
];
