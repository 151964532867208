import React from 'react';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '@store';
import ErrorBoundaryHandler from '@components/ErrorBoundaryHandler';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const staleTime = 1000 * 60 * 15;       // Data is not considered stale for 15 minutes
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({ 
  defaultOptions: { 
    queries: { 
      staleTime: staleTime,
      refetchOnWindowFocus: false 
    } 
  } 
});
const isProd = process.env.NODE_ENV === 'production';

root.render(
  <Router>
    <Provider store={store}>
      <ErrorBoundaryHandler>
        <QueryClientProvider client={queryClient}>
          <App />
          {!isProd && <ReactQueryDevtools />}
        </QueryClientProvider>
      </ErrorBoundaryHandler>
    </Provider>
  </Router>
);
